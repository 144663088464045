import React from 'react'
import Layout from '../components/layout'
import { Link, graphql, useStaticQuery } from 'gatsby'
import projectStyles from './projects.module.scss'
import Head from '../components/head'

const ProjectPage = () => {
    const data = useStaticQuery(graphql`
        query{
            allContentfulProjectPost( sort: {fields: startDate, order: DESC}) {
                edges {
                    node {
                        title
                        slug
                        client
                        startDate(formatString: "MMMM Do, YYYY")
                        endDate(formatString: "MMMM Do, YYYY")
                        keywords
                    }
                }
            }
        }
    `)

    return (
        <Layout> 
            <Head title="Projects"/>
            <h1>Projects</h1>
            <ol className={projectStyles.posts}>
                {
                    data.allContentfulProjectPost.edges.map((edge, index) => {
                        return (
                            <li className={projectStyles.post} key={index}>
                                <Link to={`/projects/${edge.node.slug}`}>
                                    <h2>{edge.node.title}</h2>
                                    <h4>{edge.node.client}</h4>
                                    <p>{edge.node.startDate} - {edge.node.endDate}</p>
                                    <div className="keywords">{edge.node.keywords.map(keyword => {return <div className="keyword">{keyword}</div>})}</div>
                                </Link>
                            </li>
                        )
                    })
                }
            </ol>
        </Layout>
    )
}

export default ProjectPage;
